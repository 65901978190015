
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "Captcha4108226129",
      {"classes":{"root":"Captcha4108226129__root","captcha":"Captcha4108226129__captcha","checkbox":"Captcha4108226129__checkbox"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  