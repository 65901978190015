export const translationFeature = 'dialogMixinTranslations';
export const PASS_MIN_LEN = 4;
export const PASS_MAX_LEN = 100;

export const testIds = {
  socialAuth: 'socialAuth',
  emailAuth: 'emailAuth',
  socialAuthMessage: 'socialAuthMessage',
  copyLinkButton: 'copyLinkButton',
};

export const MemberLoginDialogTranslationKeys = {
  title: 'dialogMixinTranslation_log_in',
  submitButton: 'dialogMixinTranslations_log_in_btn_new',
  switchDialogLink: 'SMRegister_sign_up_new',
  mobileForgotPassword: 'dialogMixinTranslations_forgot_password_mobile_new',
  forgotPassword: 'dialogMixinTranslations_forgot_password',
  switchToSignUp: 'dialogMixinTranslations_switch_to_signup_material',
  socialSectionDivider: 'dialogMixinTranslations_or_log_in_with',
  emailSectionDivider: 'dialogMixinTranslations_or_email_new',
  inAppBrowserSocialAuthSignUpMessagePrefix:
    'dialogMixinTranslations_to_sign_up_with',
  inAppBrowserSocialAuthLoginMessagePrefix:
    'dialogMixinTranslations_to_login_with',
  inAppBrowserSocialAuthLoginMessageSuffix:
    'dialogMixinTranslations_visit_another_browser',
  inAppBrowserSocialAuthMessageOr: 'dialogMixinTranslations_or',
  facebookLabel: 'dialogMixinTranslations_facebook',
  googleLabel: 'dialogMixinTranslations_google',
  copyLinkText: 'dialogMixinTranslations_copy_link',
  password: {
    title: 'SMForm_Password',
    label: 'PasswordLogin_Password',
  },
  email: {
    title: 'SMForm_Email',
    label: 'SMForm_Email',
  },
  social: {
    iframeTitle: 'dialogMixinTranslations_social_login',
    google: 'dialogMixinTranslations_login_google',
    facebook: 'dialogMixinTranslations_login_facebook',
  },
  switchToAuthWithEmail: 'dialogMixinTranslations_login_switch_email',
  linkCopied: 'dialogMixinTranslations_link_copied',
};
